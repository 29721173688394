import { createPinia, defineStore } from "pinia";

const pinia = createPinia()
export const useShoppingCart = defineStore('shoppingCart',{
    
    id: '',
    state: () => ({
        cartProducts: []
    }),
    getters: {

    },
    actions: {
        async addCartItem(newProduct){
            this.cartProducts.push(newProduct)
        },
        async deleteCartItem(deletedProduct){
            this.cartProducts = this.cartProducts.filter(item => item.id == deletedProduct.id)
        },
        async emptyCart(){
            this.cartProducts = 0;
            this.cartProducts = []
        }
    }
})
export default pinia