<template>
  <div class="bg-dark redes" id="redes">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 col-sm-12 mt-5 c-1">
          <div class="row mt-4 ml-1">
            <img src="@/assets/logoips.png" alt="logo" />
          </div>
          <div class="row">
            <p class="text-white my-4 text-footer text-justify font">
              Somos una institución con modalidad E-learning, que genera
              educación de calidad, formando especialistas en el área de
              Seguridad y Salud Ocupacional, Medio Ambiente y Calidad.
            </p>
          </div>
          
        </div>
        <div class="col-12 col-md-4 col-sm-12">
          <div class="contactenos_consultoria">
            <p class="text-white mt-5 mb-4 c-3 sub-titulo-footer text-justify font">
              CONTÁCTANOS
            </p>
            <div class="fila-contact">
              <img src="@/assets/Ubicacion.png" alt="Ubicacion IpsSoma" />
              <p class="text-white text-justify font">
                Av. La Fontana #440 – Centro Comercial “La Rotonda II” - Oficina
                2086. La Molina, Lima - Perú
              </p>
            </div>
            <div class="fila-contact">
              <img src="@/assets/Correo.png" alt="Correo IpsSoma" />
              <p class="text-white font">ipssoma1.org@gmail.com</p>
            </div>
            <div class="fila-contact">
              <img src="@/assets/telefono.png" alt="Telefono IpsSoma" />
              <p class="text-white text-justify font">
                Lic. Gladys Luján, Coordinadora académica.<br />
                Teléfono: 901 874 235
                <!-- <a href="https://wa.link/mgncot"> - Whatsapp </a> -->
              </p>
            </div>
            <div class="fila-contact">
              <img src="@/assets/telefono.png" alt="Telefono IpsSoma" />
              <p class="text-white text-justify font">
                Lic. Nancy Férnandez, Asesor educativo.<br />
                Teléfono: 928 866 727
                <!-- <a href="https://wa.link/nhxcc9"> - Whatsapp </a> -->
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-sm-12">
          <p class="text-white mt-5 mb-4 c-2 sub-titulo-footer text-justify font">
            SÍGUENOS EN
          </p>
          <div class="row fila-iconos-redes container-fluid text-right mt-3">
            <a href="https://www.facebook.com/ipssoma/" target="_blank">
              <img src="@/assets/fb_1@4x.png" alt="" style="max-width: 40px;">
            </a>
            <a href="https://www.linkedin.com/company/instituto-peruano-ssoma/" target="_blank">
              <img src="@/assets/in_1@4x.png" alt="" style="max-width: 40px;">
            </a>
            <a href="https://www.instagram.com/ipssoma" target="_blank">
              <img src="@/assets/instagram_1@4x.png" alt="" style="max-width: 40px">
            </a>
            <a href="https://www.youtube.com/channel/UCvYRjy5-aijIB3tZK-lE6gg" target="_blank">
              <img src="@/assets/youtube_1@4x.png" alt="" style="max-width: 40px">
            </a>
            <a href="https://www.tiktok.com/@ipssoma" target="_blank">
              <img src="@/assets/tiktok_1@4x.png" alt="" style="max-width: 40px;">
            </a>
          </div>
        </div>
      </div>
    </div>
 
    <div class="text-white text-center bg-secondary" style="width: 100vw;">
      © 2022 promolíder.org Copyright.
    </div>
    <BottomChat></BottomChat>
  </div>
</template>
<script>
import BottomChat from "@/components/public/SocialChat/Index.vue";
export default {
  data() {
    return {
      form: {
        user: "",
        phone: "",
        email: "",
        message: "",
        
        msg: "",
      },
    };
  },
  components: {
    BottomChat,
  },
  methods: {
    async sendQuery() {
      // if(this.validateFields()){
        const form = {
          fullname: this.form.user,
          phone: this.form.phone,
          email: this.form.email,
          message: this.form.message,
        };
        await this.$axios.post("/public/save-queries", form).then((response) => {
          console.log(response);
          this.form.user = "";
          this.form.phone = "";
          this.form.email = "";
          this.form.message = "";
          this.msg = "Enviado";
          this.snackbar = true;
        });
      // }
    },
    validateFields(){
      if(this.form.user == "" || this.form.user.length < 3){
        this.msg = "Ingrese su nombre correctamente";
        this.snackbar = true;
        return false;
      }
      if(this.form.phone == "" || this.form.phone < 900000000){
        this.msg = "Ingrese su número telefónico correctamente";
        this.snackbar = true;
        return false;
      }
      if(this.form.email == ""){
        this.msg = "Ingrese su correo";
        this.snackbar = true;
        return false;
      }
      return true;
    }
  },
};
</script>
<style>
.font {
  font-family: Roboto !important;
}

.text-justify {
  text-align: justify !important;
}

.fila-iconos-redes {
  gap: 25px;
}

.redes {
  text-align: justify;
}

.redes .fila-contact {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.redes .fila-contact img {
  width: 30px;
  height: 30px;
}

.redes .consultoria_formulario {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.redes input {
  height: 40px;
}

.redes textarea,
.redes input {
  border: none;
  outline: none;
  border-radius: 10px;
  padding-left: 10px;
}

.redes .text-footer {
  margin-right: 15px;
}

.redes .consultoria_formulario button {
  width: 100px;
}

.redes .sub-titulo-footer {
  font-size: 24px;
}

.redes .fila-contact {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.redes .fila-contact img {
  width: 30px;
  height: 30px;
}
@media (min-width: 480px) and (max-width: 960px) {
  .fila-iconos-redes{
    margin-bottom: 20px;
  }
}
</style>
